<template>
    <Toast group="globalToast" :style="{width: '30rem'}">
        <template #message="{ message }">
            <div class="flex align-items-center justify-content-between flex-1 align-self-center mr-3">
                <span class="text-xl font-medium text-900">{{ message.detail }}</span>
            </div>
        </template>
    </Toast>
</template>

<script>
export default {
    name: "ToastEmitter",

    data() {
        return {

        }
    },

    mounted() {
        this.$emitter.on("good-toast", message => {
            this.$toast.removeAllGroups();
            this.$toast.add({
                group: 'globalToast',
                detail: message,
                styleClass: 'bg-green-500',
                contentStyleClass: 'p-3',
                life: 3000
            });
        });

        this.$emitter.on("bad-toast", message => {
            this.$toast.removeAllGroups();
            this.$toast.add({
                group: 'globalToast',
                detail: message,
                styleClass: 'bg-orange-600',
                contentStyleClass: 'p-3',
                life: 3000
            });
        });
    }
}
</script>

<style scoped>

</style>
