<template>
    <div class="flex flex-column flex-auto">
        <div class="p-5">
            <div class="grid">
                <!-- login box -->
                <div class="col-12 xl:col-offset-3 xl:col-6 lg:col-offset-2 lg:col-8">
                    <div class="surface-card shadow-2 border-round p-3 mb-3">
                        <div class="text-center mb-5">
                            <div class="text-900 text-3xl font-medium mb-3">Welcome Back</div>
                        </div>

                        <div>
                            <label for="loginEmail" class="block text-900 font-medium mb-2">Username</label>
                            <InputText v-model="username" id="loginEmail" type="text" class="w-full mb-3" />

                            <label for="loginPassword" class="block text-900 font-medium mb-2">Password</label>
                            <InputText v-model="password" id="loginPassword" ref="loginPassword" type="password" class="w-full mb-3" />

                            <Button id="loginSubmit" @click="login" label="Sign In" icon="pi pi-user" class="w-full"></Button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <ToastEmitter/>
</template>

<script>
import ToastEmitter from "@/components/emitter/ToastEmitter";

export default {
    name: "Login.vue",
    components: {
        ToastEmitter
    },

    data: function() {
        return {
            username: "",
            password: "",
        }
    },

    methods: {
        login() {
            this.$factory.auth.login(this.username, this.password)
                .then(data => {
                    this.$store.commit("setLoggedIn", data['XSRF-TOKEN']);
                    this.getUserInfo();  // get user info after login and then redirect
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        this.$emitter.emit("bad-toast", "Try again! Username or password incorrect");
                    } else if (error.response.status === 429 || error.response.status === 500) {
                        this.$emitter.emit("bad-toast", 'Your account has been locked for too many failed login attempts. Contact your account manager for assistance');
                    } else {
                        this.$emitter.emit("bad-toast", "Network Error! Something happened, try again or if the issue persists get in touch with your account manager");
                    }
                });
        },

        getUserInfo() {
            this.$factory.auth.getUserInfo()
                .then(data => {
                    this.$store.commit("setUserInfo", data);
                    this.$router.push("/");  // redirect to home page when we are sure the user is logged in
                })
                .catch(error => {
                    console.error(error);
                    this.$emitter.emit("bad-toast", "Network Error! Something happened, try again or if the issue persists get in touch with your account manager");
                });
        },
    },
};
</script>

<style lang="scss">
    @import '@/assets/styles/layout.scss';
</style>
